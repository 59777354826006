











import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class InsCatLayout2 extends Vue {
    @Prop({ default: () => [] }) private cmsData!: object[]; // cms内容数据
}
